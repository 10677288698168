<template>
  <v-form class="contact-form" v-model="valid" ref="form">
    <v-text-field
      class="pb-2"
      v-model="name"
      label="Nome"
      :rules="[ruleRequired]"
      filled
    ></v-text-field>
    <v-text-field
      class="pb-2"
      v-model="email"
      label="E-mail"
      :rules="[emailRule]"
      filled
    ></v-text-field>

    <v-textarea
      class="pb-2"
      v-model="commento"
      label="Commento"
      :rules="[ruleRequired]"
      filled
    ></v-textarea>
    <v-row class="justify-end">
      <v-btn
        class="mr-4 submit-button"
        color="#29CED3"
        @click="onSubmit"
        :disabled="!valid"
        >Invia</v-btn
      >
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      name: "",
      email: "",
      commento: "",
      valid: false,
    };
  },
  computed: {
    ruleRequired() {
      return (v) => !!v || "Campo richiesto";
    },
    emailRule() {
      return (v) =>
        v &&
        v.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
          ? true
          : "L'indirizzo email inserito non é valido";
    },
  },
  methods: {
    async onSubmit() {
      let payload = {
        nome: this.name,
        email: this.email,
        commento: this.commento,
      };
      this.$emit("submitForm", payload);
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.contact-form {
  max-width: 600px;
  .submit-button {
    color: #2f4595;
    font-weight: bold;
    font-size: 14px;
  }
  .theme--light.v-btn.v-btn--disabled{

    color: rgba(0,0,0,.76)!important;
  }
}
</style>
