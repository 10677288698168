<template>
  <div class="contatti">
    <!-- page title -->
    <page-title></page-title>

    <!-- contatti header -->
    <v-container>
      <div class="contacts-header col-md-10 pt-16">
        <p>
          Scrivi alla redazione per richiedere informazioni o per suggerire un
          modo nuovo per riutilizzare i dati pubblici
        </p>
      </div>

      <!-- form di contatto -->
      <v-container class="pt-8">
        <v-col cols="12" md="6">
          <contact-form @submitForm="submitForm"></contact-form>
        </v-col>
      </v-container>

      <!-- team section -->
      <p class="team-header pt-8">Il team</p>
      <div class="contacts-header" v-html="intestazione">
      </div>
      <v-row>
        <v-col
          class="col-12 col-md-4 col-lg-3"
          v-for="person in team"
          :key="person.titolo"
        >
          <team-card :person="person"></team-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import TeamCard from "../components/TeamCard";
import ContactForm from "../components/ContactForm";
import { getTeam, sendMail, getIntestazione } from "../services/api";
export default {
  name: "Contatti",
  components: {
    PageTitle,
    TeamCard,
    ContactForm,
  },
  data() {
    return {
      team: [],
      intestazione: ""
    };
  },
  async created() {
    this.$emit("setLoading", true);
    
    let intestazionePromise = getIntestazione("TEAM");
    
    let teamPromise = getTeam();
    try {
      let { data } = await teamPromise;
      this.team = data;
    } catch (err) {
      console.log(err);
    }

    try {
      let { data } = await intestazionePromise;
      // decode special characters
      var txt = document.createElement("textarea");
      txt.innerHTML = data[0].intestazione;
      this.intestazione = txt.value;
    } catch (err) {
      console.log(err);
    }

    this.$emit("setLoading", false);
  },
  methods: {
    async submitForm(payload) {
      let sendMailPromise = sendMail(payload);
      try {
        let { data } = await sendMailPromise;
        if (data.message == "ok") {
          this.$notify({
            group: "userFeedback",
            title: "Successo!",
            text: "La mail è stata inviata con successo.",
            type: "success",
          });
        } else {
          this.$notify({
            group: "userFeedback",
            title: "Errore!",
            text: data.error[0],
            type: "error",
          });
        }
      } catch (err) {
        console.log(err);
        this.$notify({
          group: "userFeedback",
          title: "Errore!",
          text: "Un errore imprevisto ha impedito l'invio della mail.",
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.contatti {
  a {
    color: #2ab5b9;
  }
  .contacts-header {
    font: normal normal normal 21px/40px Open Sans;
    letter-spacing: 0px;
    color: $text-dark;
  }
  .team-header {
    font: normal normal 600 24px/40px Open Sans;
    letter-spacing: 0px;
    color: $text-dark;
  }
}
</style>