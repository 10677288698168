<template>
  <div
    class="title-bkg pb-8 pb-sm-16 pb-lg-8"
    :class="{ mobile: $vuetify.breakpoint.xsOnly }"
  >
    <v-container class="pb-xl-16">
      <h1 class="page-title pa-0 py-md-4 my-sm-4">
        {{ title }}
      </h1>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    pageTitle: { type: String, required: false, default: "" },
  },
  computed: {
    title() {
      return this.pageTitle != "" ? this.pageTitle : this.$route.meta.title;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.title-bkg {
  background-image: url("../assets/header_interna.png");
  background-size: 100% 100%;
  .page-title {
    font-weight: bold;
    font: normal normal bold 54px/66px Open Sans;
    color: $text-white;
  }
}
.mobile {
  .page-title {
    font-size: 24px;
  }
}
</style>
