<template>
  <v-card height="350">
    <div class="d-flex justify-center">
      <v-avatar class="ma-8" size="128">
        <v-img :src="baseUrl + imgUrl"></v-img>
      </v-avatar>
    </div>
    <div class="card-section text-center">
      <v-card-text class="team-title">{{ fullName }}</v-card-text>
      <v-card-text
        class="team-description"
        v-html="presentazione"
      ></v-card-text>
    </div>
    <!-- <div class="team-mail">
      <v-card-text>
        <v-row class="justify-center">
          <v-icon class="mail-icon">mail</v-icon>
          <span class="mail-text">{{ email }}</span>
        </v-row>
      </v-card-text>
    </div> -->
  </v-card>
</template>

<script>
export default {
  name: "TeamCard",
  props: {
    person: { type: Object, required: false },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    nome() {
      return this.person?.nome ?? "";
    },
    cognome() {
      return this.person?.cognome ?? "";
    },
    fullName() {
      return this.nome + " " + this.cognome;
    },
    imgUrl() {
      return this.person?.url_immagine ?? "";
    },
    presentazione() {
      let presentazione = this.person?.presentazione ?? "";
      var txt = document.createElement("textarea");
      txt.innerHTML = presentazione;
      return txt.value;
    },
    email() {
      return this.person?.email ?? "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.team-title {
  font: normal normal 600 24px/30px Open Sans;
  letter-spacing: 0px;
  color: $text-dark;
  height: 40px;
  word-break: break-word;
}
.team-description {
  font: normal normal normal 16px/24px Open Sans;
  letter-spacing: 0px;
  color: $text-dark;
  height: 100px;
}
.team-mail {
  font: normal normal normal 14px/40px Open Sans;
  letter-spacing: 0px;
  color: $text-dark;
  .mail-icon {
    color: $text-dark;
  }
  .mail-text {
    word-break: break-word;
  }
}
</style>
